import React, { useEffect, useMemo, useState } from "react"
import PageRoot from "../components/PageRoot"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import AtsGrid from "../components/AtsGrid"
import moment from "moment"
import { renderCarouselItem, toPascalCase } from "../utils"

const WebinarPage = ({ data }) => {
  const { t } = useTranslation()
  const [now, setNow] = useState()

  const allWebinars = data.ats?.webinars || []

  useEffect(() => {
    setNow(moment().add(-1, "hour"))
  }, [])

  const webinarGroup = useMemo(() => {
    let result = {}

    allWebinars.map(webinar => {
      if (webinar.available_date && moment(webinar.available_date) < now) {
        return
      }

      const dateKey = moment(webinar.available_date).format("MMMM")
      result[dateKey] = result[dateKey] || []

      result[dateKey].push(webinar)
    })

    return result
  }, [now])

  return (
    <PageRoot title={t("label:liveWebinar")} showTitle>
      <ContainerWithPadding size={"large"}>
        {Object.keys(webinarGroup).map(key => {
          const webinars = webinarGroup[key]

          return (
            <AtsGrid
              key={key}
              title={toPascalCase(key)}
              dataSource={webinars}
              renderItem={renderCarouselItem}
            />
          )
        })}
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query getAllWebinars {
    ats {
      webinars(
        where: { state: { _eq: PUBLISHED } }
        order_by: { available_date: asc_nulls_first }
      ) {
        __typename
        id
        title
        subtitle
        premium_only
        short_description
        landscape {
          ...LandscapeImage
        }
        author_rels {
          author {
            firstname
            lastname
          }
        }
        url
        video {
          course_rel {
            course {
              seo {
                slug
              }
            }
          }
          seo {
            slug
          }
        }
        available_date
      }
    }
  }
`

export default WebinarPage
